import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  updateJobAlertCreateDialogWithKeyword,
  updateJobAlertsDeleteDialog,
  updateJobAlertsDownloadAppDialog,
  updateJobAlertsNotificationDialog,
} from "../../../redux/actions/dialog_action";
import { deleteJobAlert, getJobAlerts, updateJobAlert } from "../../../redux/actions/job_action";
import * as types from "../../../redux/types/job_type";
import {
  AdditionalFilterContainer,
  AlertContainer,
  AlertContent,
  AlertViaContainer,
  BodyContainer,
  BodyContent,
  BodyContentSubtitle,
  BodyContentTitle,
  BottomButtonText,
  ButtonContainer,
  ButtonGroup,
  ButtonText,
  CheckboxContainer,
  CheckboxGroup,
  CheckedIcon,
  CheckStyled,
  CloseIcon,
  CreateButton,
  DeleteButton,
  DropdownItem,
  DropdownListContainer,
  DropdownText,
  ErrorIconContainer,
  FrequencyContainer,
  Line,
  SalaryContainer,
  SliderContainer,
  StyledButton,
  StyledCheckbox,
  StyledContentTop,
  StyledDialog,
  StyledDialogContent,
  StyledErrorIcon,
  StyledFormControlLabel,
  StyledLine,
  StyledSlider,
  StyledTextField,
  TextFieldContainer,
  TitleContainer,
  TitleContent,
  TopContentSubtitle,
  TopContentTitle,
  UncheckedIcon,
  UncheckStyled,
  YOEContainer,
} from "./styles";
import { Typography, useMediaQuery } from "@mui/material";
import { isEmpty, set } from "lodash";
import { fetchingJobList, updateJobTitleSuggestionsDropdownStatus } from "../../../redux/actions/user_action";

function JobAlertsNotificationDialog(props) {

  const { selectedCountry, jobAlerts, triggerToast } = props;

  const dispatch = useDispatch();

  const screenMobile = useMediaQuery("(max-width:768px)");

  //useSelector-Start
  const jobAlert = useSelector((state) => state.jobs.jobAlert);
  const showJobAlertNotificationsDialog = useSelector(
    (state) => state.dialog.showJobAlertNotificationsDialog
  );
  const experienceLevelsList = useSelector(
    (state) => state.jobs.experienceLevels
  );
  const jobTitleDropdownStatus = useSelector(
    (state) => state.user.jobTitleDropdownStatus
  );
  const jobAlertTitles = useSelector(
    (state) => state.user?.onboardingJobTitles
  );
  //useSelector-End

  //useState-Start
  const [width, setWidth] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [selectedFrequency, setSelectedFrequency] = useState("");
  const [selectedMedium, setSelectedMedium] = useState("");
  const [salary, setSalary] = useState(0);
  const [yearOfExperience, setYearOfExperience] = useState([]);
  const [keywordError, setKeywordError] = useState(false);
  const [keywordErrorMessage, setKeywordErrorMessage] = useState("");
  const [onHover, setOnHover] = useState(null);
  const [isFocus, setFocus] = useState(false);

  //to get value directly from jobalert first because checkbox are not checked because of useState delay
  const [valueOrigin, setValueOrigin] = useState(true);
  //useState-End

  //useEffect-Start
  useEffect(() => {

    setKeyword(jobAlert.title);
    setSelectedFrequency(jobAlert.frequency);
    setSelectedMedium(jobAlert.medium);
    setSalary(jobAlert.salary);
    setYearOfExperience(jobAlert.yearOfExperience);
  }, [jobAlert]);

  useEffect(() => {
    window.addEventListener("resize", windowOnResizeListener);

    if (!width) {
      setWidth(window.innerWidth);
    }

    return () => {
      window.removeEventListener("resize", windowOnResizeListener);
    };
  }, []);
  //useEffect-End

  const windowOnResizeListener = () => {
    setWidth(window.innerWidth);
  };

  const fetchJob = _.debounce((value) => {
    dispatch(fetchingJobList(value));
  }, 300);

  const debouncedFetchJob = useCallback((value) => fetchJob(value), []);
  // Done fetching job from lightcast

  //Validation-Start
  const keywordValidate = (keyword) => {

    if (isEmpty(keyword) || keyword?.length === 0 || keyword === "") {

      setKeywordError(true);
      setKeywordErrorMessage("Required field");
    }
    else if (!keyword || (keyword && typeof keyword === "string" && keyword?.trim()?.length == 0)) {

      setKeywordError(true);
      setKeywordErrorMessage("Invalid keyword");
    }
    else setKeywordError(false);
  }
  //Validation-End

  //Form Inputs OnChange-Start
  const onKeywordUpdate = (event) => {

    const newKeyword = event?.target?.value;
    setKeyword(event?.target?.value);
    keywordValidate(newKeyword);

    if (!event?.nativeEvent?.inputType) {

      dispatch(updateJobTitleSuggestionsDropdownStatus(false));
      setKeywordError(false);
      return;
    }

    dispatch(
      updateJobAlertCreateDialogWithKeyword({
        keyword: event?.target?.value,
      })
    );

    if (keyword?.length >= 3 && !jobTitleDropdownStatus) {
      dispatch(updateJobTitleSuggestionsDropdownStatus(true));
    }

    if (keyword) {
      debouncedFetchJob(event.target?.value);
    }
  };

  const onSelectJobTitle = (suggestedJobTitle) => {
    setKeyword(suggestedJobTitle);
    dispatch(updateJobTitleSuggestionsDropdownStatus(false));
  };

  const handleButtonChange = (buttonValue, type) => {

    type === "frequency" ? setSelectedFrequency(buttonValue) : setSelectedMedium(buttonValue);

    if (type === "medium" && (selectedMedium === "in-app" || selectedMedium === "both"))
      dispatch(updateJobAlertsDownloadAppDialog(true));
  }
  
  const handleSlider = (event) => {
    
    setValueOrigin(false);
    const newValue = parseInt(event?.target?.value);
    
    const step = newValue < 5000 
      ? 200 
      : newValue >= 5000 && newValue < 10000 
        ? 500 
        : 1000;
  
    const roundedValue = Math.round(newValue / step) * step;
    
    setSalary(roundedValue);
  }

  const handleCheckbox = (event) => {

    setValueOrigin(false);
    const value = event?.target?.name;

    if (event?.target?.checked) {
      setYearOfExperience(prev => Array.isArray(prev) ? [...prev, parseInt(value)] : [parseInt(value)]);
    }
    else {
      setYearOfExperience(prev => Array.isArray(prev) ? prev.filter(item => item !== parseInt(value)) : []);
    }
  }
  //Form Inputs OnChange-End

  //Form Buttons OnClick-Start
  const handleClose = () => {

    setSelectedFrequency("");
    setSelectedMedium("");
    setKeyword("");
    setYearOfExperience([]);
    setSalary(0);
    setKeywordError(false);
    setKeywordErrorMessage("");
    dispatch(updateJobAlertsNotificationDialog(false));
  };

  const handleSave = () => {

    keywordValidate(keyword);

    if (keywordError) return;
    if (isEmpty(keyword)) return; //doublecheck

    //Check duplicate title
    let checkKeyword = { title: keyword };
    let checkDuplicateResult = jobAlerts.filter(JA => JA.title.toLowerCase() === checkKeyword.title.toLowerCase());

    //if found duplicate title return null
    if (checkDuplicateResult?.length > 0 && jobAlert.id !== checkDuplicateResult[0].id) {
      triggerToast("Job alert already exists", false);
      return;
    }

    let params = {
      id: jobAlert?.id,
      keyword: keyword,
      frequency: selectedFrequency,
      medium: selectedMedium,
      yearOfExperience: yearOfExperience ?? [],
      salary: salary,
      activate: jobAlert?.activate,
      useFullUrl: true,
      country: selectedCountry,
    };

    dispatch(updateJobAlert(params)).then((response) => {
      if (response.type === types.UPDATE_JOB_ALERT_SUCCEED) {
        triggerToast("Job alert updated", true);
        sendTrackingEvent({
          event: "update-job-alert",
          "job-alert-id": response.jobAlert.id,
          "job-alert-frequency": response.jobAlert.frequency,
          "job-alert-medium": response.jobAlert.medium,
          // "job-alert-year-of-experience": response.jobAlert?.yearOfExperience,
          // "job-alert-salary": response.jobAlert?.salary,
        });
      } else {
        triggerToast("Failed to update job alert, please try again later", false);
      }
      dispatch(updateJobAlertsNotificationDialog(false));
      const params = {
        useFullUrl: true,
        country: selectedCountry,
      };
      dispatch(getJobAlerts(params));
    });
  };

  const handleDelete = () => {

    let params = {
      id: jobAlert.id,

      useFullUrl: true,
      country: selectedCountry,
    };

    dispatch(deleteJobAlert(params)).then((response) => {
      if (response.type === types.DELETE_JOB_ALERT_SUCCEED) {
        triggerToast("Job alert deleted", true);

        sendTrackingEvent({
          event: "delete-job-alert-profile",
          "job-alert-id": params.id,
        });
      } else {
        triggerToast("Failed to delete job alert, please try again later", false);
      }
      dispatch(updateJobAlertsDeleteDialog(false));
    });
    handleClose();
  };
  //Form Buttons OnClick-End

  return (
    <Fragment>
      <StyledDialog
        open={showJobAlertNotificationsDialog}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            event.stopPropagation();
            handleSave();
          },
        }}
      >
        <StyledDialogContent>
          <StyledContentTop>
            <TitleContainer>
              <TitleContent>
                <TopContentTitle
                  isMobile={screenMobile}
                  sx={{ color: "var(--Text-Light-Theme-High-Emphasis, rgba(0, 0, 0, 0.87))" }}
                >
                  {screenMobile ? "Create new job alert" : "Get alerted with new jobs"}
                </TopContentTitle>
                <TopContentSubtitle
                  isMobile={screenMobile}
                  sx={{ color: "var(--Text-Light-Theme-Medium-Emphasis, rgba(0, 0, 0, 0.60))" }}
                >
                  Never miss out, be the first to receive matches!
                </TopContentSubtitle>
              </TitleContent>
              <CloseIcon onClick={handleClose} />
            </TitleContainer>
          </StyledContentTop>
          <BodyContainer>
            <BodyContent>
              <TextFieldContainer>
                <BodyContentTitle isMobile={screenMobile}>
                  Keyword*
                </BodyContentTitle>
                <StyledTextField
                  isError={keywordError}
                  isMobile={screenMobile}
                  onChange={onKeywordUpdate}
                  onFocus={() => {setFocus(true)}}
                  onBlur={() => {
                    setTimeout(() => {
                      setFocus(false);
                    }, 1000);
                  }}
                  value={keyword}
                  name="keyword"
                  variant="outlined"
                  placeholder="Eg. Data analyst"
                  error={keywordError}
                  helperText={keywordError && keywordErrorMessage}
                  InputProps={{
                    endAdornment: keywordError && (
                      <ErrorIconContainer position="end">
                        <StyledErrorIcon />
                      </ErrorIconContainer>
                    ),
                  }}
                />
                {keyword?.length >= 3 && jobTitleDropdownStatus && !keywordError && isFocus && (
                  <DropdownListContainer>
                    {jobAlertTitles.slice(0, 5).map((item, index) => {
                      return (
                        <DropdownItem
                          key={`${item}-${index}`}
                          onClick={() => onSelectJobTitle(item)}
                        >
                          <DropdownText className="dropdown">{item}</DropdownText>
                        </DropdownItem>
                      );
                    })}
                  </DropdownListContainer>
                )}
              </TextFieldContainer>
              <AlertContainer>
                <BodyContentTitle isMobile={screenMobile}>
                  Alert settings*
                </BodyContentTitle>
                <AlertContent>
                  <FrequencyContainer>
                    <BodyContentSubtitle isMobile={screenMobile}>
                      Select frequency:
                    </BodyContentSubtitle>
                    <ButtonGroup>
                      <StyledButton type="button" active={selectedFrequency === "daily"} onClick={() => handleButtonChange("daily", "frequency")}>
                        <ButtonText isMobile={screenMobile} active={selectedFrequency === "daily"}>Daily</ButtonText>
                      </StyledButton>
                      <StyledButton type="button" active={selectedFrequency === "weekly"} onClick={() => handleButtonChange("weekly", "frequency")}>
                        <ButtonText isMobile={screenMobile} active={selectedFrequency === "weekly"}>Weekly</ButtonText>
                      </StyledButton>
                    </ButtonGroup>
                  </FrequencyContainer>
                  <AlertViaContainer>
                    <BodyContentSubtitle isMobile={screenMobile}>
                      Alert via:
                    </BodyContentSubtitle>
                    <ButtonGroup>
                      <StyledButton type="button" active={selectedMedium === "email"} onClick={() => handleButtonChange("email", "alert-via")}>
                        <ButtonText isMobile={screenMobile} active={selectedMedium === "email"}>Email</ButtonText>
                      </StyledButton>
                      <StyledButton type="button" active={selectedMedium === "notification"} onClick={() => handleButtonChange("notification", "alert-via")}>
                        <ButtonText isMobile={screenMobile} active={selectedMedium === "notification"}>In-app notifications</ButtonText>
                      </StyledButton>
                      <StyledButton type="button" active={selectedMedium === "all"} onClick={() => handleButtonChange("all", "alert-via")}>
                        <ButtonText isMobile={screenMobile} active={selectedMedium === "all"}>Both</ButtonText>
                      </StyledButton>
                    </ButtonGroup>
                  </AlertViaContainer>
                </AlertContent>
              </AlertContainer>
              <StyledLine />
              <AdditionalFilterContainer>
                <BodyContentTitle isMobile={screenMobile}>
                  Additional filters
                </BodyContentTitle>
                <YOEContainer>
                  <BodyContentSubtitle isMobile={screenMobile}>
                    Years of experience:
                  </BodyContentSubtitle>
                  <CheckboxGroup>
                    {experienceLevelsList.map((yoe, index) => (
                      <CheckboxContainer>
                        <StyledFormControlLabel
                          onMouseEnter={() => { setOnHover(index) }}
                          onMouseLeave={() => { setOnHover(null) }}
                          key={parseInt(yoe.id)}
                          control={
                            <StyledCheckbox name={parseInt(yoe.id)}
                              checked={valueOrigin ? jobAlert.yearOfExperience?.includes(parseInt(yoe.id)) : yearOfExperience?.includes(parseInt(yoe.id))}
                              icon={
                                <UncheckedIcon sx={{ border: onHover === index && "1px solid #222222 !important" }}>
                                  {onHover === index && <UncheckStyled />}
                                </UncheckedIcon>
                              }
                              checkedIcon={
                                <CheckedIcon>
                                  <CheckStyled />
                                </CheckedIcon>
                              }
                              onChange={handleCheckbox}
                            />
                          }
                          labelPlacement={screenMobile ? "start" : "end"}
                          label={yoe.title}
                          isMobile={screenMobile}
                        />
                      </CheckboxContainer>
                    ))}
                  </CheckboxGroup>
                </YOEContainer>
                <SalaryContainer>
                  <BodyContentSubtitle isMobile={screenMobile}>
                    Minimum monthly salary:
                  </BodyContentSubtitle>
                  <SliderContainer>
                    <StyledSlider onChange={handleSlider}
                      min={0} max={25000}
                      step={salary < 5000 ? 200 : salary >= 5000 && salary < 10000 ? 500 : 1000}
                      value={valueOrigin ? jobAlert.salary : salary}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "bold",
                        color: "rgba(0, 0, 0, 0.87)",
                        lineHeight: "171.429%",
                        alignSelf: "stretch",
                        fontSize: !screenMobile ? "14px" : "12px",
                      }}
                    >
                      {selectedCountry === "my" ? `RM ${salary}` : `${salary} SGD`}
                    </Typography>
                  </SliderContainer>
                </SalaryContainer>
              </AdditionalFilterContainer>
            </BodyContent>
          </BodyContainer>
        </StyledDialogContent>
        <ButtonContainer>
          <DeleteButton isMobile={screenMobile} type="button" onClick={handleDelete} onMouseEnter={() => { setOnHover(100) }} onMouseLeave={() => { setOnHover(null) }}>
            <BottomButtonText side={'left'} onHover={onHover}>Delete</BottomButtonText>
          </DeleteButton>
          <CreateButton type="submit">
            <BottomButtonText side={'right'}>Create Alert</BottomButtonText>
          </CreateButton>
        </ButtonContainer>
      </StyledDialog>
    </Fragment>
  );
}

export default JobAlertsNotificationDialog;